















import { Component, Prop, Vue } from 'vue-property-decorator';
import { IStrDetailValue } from '@/Services/Interfaces';
import { globalUpdateDetailService } from '@/Services/GlobalService';
import { InfoType, CrudType } from '@/Services/enums';
import BaseSubComponent from './BaseSubComponent.vue';


@Component({ components: { BaseSubComponent } })
export default class IFrameComponent extends BaseSubComponent {

    constructor()
    {
        super();
        this.name = "IFrameComponent";

        this.model = <IStrDetailValue>{};
    }

   name : string;




   onUpdate(model: any) {
      if (this.canUpdate(model)) {
      {
        model.crud = CrudType.updatestrvalue;
        globalUpdateDetailService.sendMessage(model);
      }
    }
   }

    data()  {
        return {
            thismodel:  this.model,
        }
    }


  computed() {
  };


}
